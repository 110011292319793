<template>
  <v-container>
    <generalAlert
      v-if="alert"
      :alert="alert"
      :alertType="alertType"
      :message="successResponse"
    />
    <v-row class="py-4">
      <v-col
        v-if="this.$store.state.loggedIntoFacebook == false"
        cols="6"
        class="text-center"
      >
        <h2 class="steptext">Welcome to Compeat</h2>
        <p>Please sign in to Facebook to continue.</p>
        <v-btn @click="loginWithFacebook()" rounded class="primary mb-2">
          <v-icon>mdi-facebook</v-icon> Login To Facebook
        </v-btn>
        <p v-if="this.cancelledLogin">
          Client Cancelled Signin. please try again.
        </p>
      </v-col>

      <v-col
        v-if="this.submitButton && this.$store.state.loggedIntoFacebook == true"
        cols="6"
      >
        <v-card class="compeatform">
          <v-form ref="form" v-model="valid">
            <v-card-text>
              <v-row justify="center" align="center">
                <v-col cols="3"> </v-col>
                <v-col cols="6" class="text-center">
                  <h1 class="steptext">Personal Info</h1>
                </v-col>
                <v-col cols="3">
                  <v-btn rounded @click="logoutFromFacebook()" class="primary">
                    <v-icon class="mr-1">mdi-facebook</v-icon> Logout
                  </v-btn>
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-text-field
                    v-model="firstName"
                    label="First Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-text-field
                    v-model="lastName"
                    label="Last Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <vue-tel-input-vuetify
                    id="mobile"
                    v-model="mobile"
                    data-ref="mobile"
                    defaultCountry="za"
                    @input="onInput"
                    clearable
                    label=""
                  ></vue-tel-input-vuetify>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="Email Address"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="
                    (packagePages.length >= 0 && packagePages.length < 5) ||
                    (packageUpgraded && packagePages.length < 10)
                  "
                  class="text-center"
                >
                  <h3>
                    You can add
                    {{ pagesToAdd - this.packagePages.length }} more
                    competitor(s)...
                  </h3>
                </v-col>
                <v-col
                  v-if="
                    packagePages.length < 5 ||
                    (packageUpgraded && packagePages.length < 10) ||
                    agreement
                  "
                  class="col-12 py-0"
                >
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        type="text"
                        class="facebookHandleInput"
                        v-model="search"
                        placeholder="Competitive Pages "
                        @keyup="searchForCompany()"
                        v-on="on"
                        v-bind="attrs"
                        :loading="loadingSearch"
                      />
                    </template>

                    <v-list class="listofpages">
                      <v-list-item v-if="this.search == '' && !loadingSearch">
                        <v-list-item-title>Type to search </v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="this.searchPageError == true">
                        <v-list-item-title
                          >{{ searchErrorMessage }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="loadingSearch == true">
                        <v-list-item-title
                          >Please Wait, pages logging
                          <v-progress-circular indeterminate color="primary">
                          </v-progress-circular
                        ></v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-for="searchResult in searchResults"
                        :key="searchResult.id"
                      >
                        <v-avatar size="40" class="mr-2">
                          <v-img :src="searchResult.icon" />
                        </v-avatar>
                        <v-list-item-title @click="selectCompany(searchResult)">
                          {{ searchResult.name }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col v-if="packagePages != ''" cols="12">
                  <v-list>
                    <v-list-item
                      v-for="(packagePages, index) in packagePages"
                      :key="packagePages.id"
                      class="py-2"
                    >
                      <v-avatar size="40" class="mr-2">
                        <v-img :src="packagePages.icon" />
                      </v-avatar>
                      <v-list-item-content class="text-left">
                        <v-list-item-title>
                          {{ packagePages.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-content v-if="index == 0">
                        <v-list-item-title> - Primary page </v-list-item-title>
                      </v-list-item-content>
                      <v-btn @click="deletePage(index)" icon>
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col class="text-left" cols="12">
                  <h4>
                    Consent*:
                    <a href="/terms-and-conditions">terms & conditions</a>
                  </h4>
                </v-col>
                <v-col class="text-left py-0" cols="10">
                  <v-checkbox
                    :rules="checkboxRules"
                    :disabled="packagePages.length < 2"
                    hide-details
                    label="I agree to the terms & conditions"
                    v-model="checkbox"
                    class="mt-0"
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="2" class="text-right py-0">
                  <v-tooltip bottom v-if="packagePages.length < 2">
                    <template #activator="{ on }">
                      <v-icon v-on="on" class="icon-with-tooltip"
                        >mdi-comment-question-outline</v-icon
                      >
                    </template>
                    <span>You need atleast 1 competitor page.</span>
                  </v-tooltip>
                </v-col>
                <v-col v-if="checkbox == true" cols="12">
                  <v-divider class="my-3"></v-divider>
                  <h2 class="mb-3 bold">Checkout Selected Package</h2>
                </v-col>
                <v-col v-if="checkbox == true" cols="12">
                  <v-card outlined>
                    <v-card-text>
                      <v-row justify="center" align="center">
                        <v-col cols="8" sm="8" md="8" lg="8" class="text-left">
                          <h2 class="my-0">{{ packageTitle }}</h2>
                          <p class="mb-0">
                            Primary page - {{ this.packagePages[0].name }}
                          </p>
                          <p class="mb-0" v-if="this.packagePages.length < 3">
                            {{ this.packagePages.length - 1 }} Competitor -
                            {{
                              this.packagePages
                                .slice(1)
                                .map((page) => page.name)
                                .join(", ")
                            }}
                          </p>
                          <p v-else class="mb-0">
                            {{ this.packagePages.length - 1 }} Competitors -
                            {{
                              this.packagePages
                                .slice(1)
                                .map((page) => page.name)
                                .join(", ")
                            }}
                          </p>
                          <p class="mb-0">
                            Competitive Analysis Report Monthly
                          </p>
                          <p class="mb-0">Facebook Insights Report Monthly</p>
                          <p class="mb-0">Instagram Insights Report Monthly</p>
                        </v-col>
                        <v-col
                          cols="4"
                          sm="4"
                          md="4"
                          lg="4"
                          class="text-center"
                        >
                          <v-card color="primary" dark class="rounded-card">
                            <v-card-text>
                              <h2>${{ priceTotal }}</h2>
                              <p class="my-0">per month</p>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <p><bold>*Note:</bold> Too generate all 3 reports you will need to have admin access to the primary page you are doing the reports on. <br> If you dont hvae access at this moment dont worry, you will be able to login to the dashbord and view the subscription and generate the insight reports for this month.</p>
                </v-col>
                <v-col cols="12" class="text-right pb-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col class="text-left" cols="6">
                  <h2>Total:</h2>
                </v-col>
                <v-col class="text-right" cols="6">
                  <h2>$ {{ priceTotal }}</h2>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row class="actionsbuttons" v-if="submitButton">
                <v-col class="col-6 text-center">
                  <v-btn
                    rounded
                    @click="clearOperation()"
                    class="secondary white--text"
                  >
                    Cancel
                  </v-btn>
                </v-col>
                <v-col class="col-6 text-center">
                  <v-btn
                    :loading="loading"
                    :disabled="!valid"
                    rounded
                    @click="checkIfUserExist()"
                    class="primary white--text"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>

      <v-col v-if="this.displayBillingDetails" cols="6" class="text-center">
        <v-card class="compeatform">
          <v-row>
            <v-col cols="12" class="text-center">
              <h2 class="mb-3 steptext">Subscription Billing</h2>
              <v-card outlined>
                <v-card-text>
                  <v-row justify="center" align="center">
                    <v-col cols="8" sm="8" md="8" lg="8" class="text-left">
                      <h2 class="my-0">{{ packageTitle }}</h2>
                      <p class="mb-0">
                        Primary page - {{ this.packagePages[0].name }}
                      </p>
                    </v-col>
                    <v-col cols="4" sm="4" md="4" lg="4" class="text-center">
                      <v-card color="primary" dark class="rounded-card">
                        <v-card-text>
                          <h3>${{ priceTotal }}</h3>
                          <p class="my-0">per month</p>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-if="showCards" justify="center" align="center">
            <v-col cols="2"> </v-col>
            <v-col cols="8" class="text-center">
              <h2>Client Cards</h2>
            </v-col>
            <v-col cols="2">
              <addCard
                :stripeCustomerId="this.stripeClient.id"
                :agencyPaying="agencyPaying"
                @searchForCustomerEmail="searchForCustomerEmail"
              />
            </v-col>
            <v-col cols="12" class="text-center py-0">
              <v-card flat>
                <v-card-text class="py-0 px-0">
                  <v-list-item-group v-if="otherCards == 0">
                    <v-list-item class="py-2">
                      <v-row justify="center" align="center">
                        <v-col cols="12">
                          <p class="mb-0">No Cards. Please Add Bank Card</p>
                        </v-col>
                      </v-row>
                    </v-list-item>
                  </v-list-item-group>
                  <v-list-item-group v-if="otherCards">
                    <v-list-item
                      v-for="(card, index) in otherCards"
                      :key="card.id"
                      class="py-2"
                      @change="handleCheckboxChange(card.id)"
                    >
                      <v-row justify="center" align="center">
                        <v-col cols="1">
                          <v-checkbox
                            hide-details
                            v-model="selectedCard"
                            :value="card.id"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="1">
                          <v-icon>mdi-credit-card</v-icon>
                        </v-col>
                        <v-col v-if="index === 0" cols="2">
                          <v-chip small link color="primary"> Default </v-chip>
                        </v-col>
                        <v-col v-else cols="2"> </v-col>
                        <v-col cols="6">
                          **** **** **** {{ card.card.last4 }}
                        </v-col>
                        <v-col cols="2">
                          {{ card.card.brand }}
                        </v-col>
                      </v-row>
                    </v-list-item>
                  </v-list-item-group>
                  <v-row class="my-2" v-if="defaultCard != 0">
                    <v-col cols="12">
                      <v-btn
                        @click="submitCardDetails()"
                        rounded
                        color="primary"
                        class="mx-2"
                        >Continue Purchase</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col
        v-if="this.displayGeneratingReport == true || this.displayGeneratingInsightsReport == true"
        cols="6"
        class="text-center"
      >
        <v-card class="compeatform">
          <v-row>
            <v-col cols="12">
              <h2 class="mb-3 steptext" v-if="this.displayGeneratingReport == true">Generating Compeatitive Report</h2>
               <h2 class="mb-3 steptext" v-else>Generating Insights Report</h2>
            </v-col>
            <v-col cols="12">
              <v-alert
                border="left"
                colored-border
                color="primary"
                elevation="2"
              >
                <h3 v-if="this.displayGeneratingReport == true" class="mb-2">
                  Busy Generating Competitive Analysis Report
                </h3>
                <h3 v-else class="mb-2">
                  Busy Generating Competitive Analysis Report
                </h3>
                <p>Please be patient your report will be done soon!</p>
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </v-alert>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col v-if="this.displayNextStep" cols="6">
        <v-card class="compeatform">
          <v-row>
            <v-col cols="12" class="text-center">
              <h1 class="steptext">Subscription Info</h1>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" v-if="!emailClientForAccess">
            <v-col cols="12" class="text-center">
              <p>Checking For Page Access to do the insights reports.</p>
            </v-col>
          </v-row>
          <v-row v-if="emailClientForAccess">
            <v-col cols="12" class="text-center">
              <p>
                Client Needs to give us access to <br />
                Facebook and Instagram pages.
              </p>
              <v-btn
                :loading="loading"
                class="primary white--text"
                rounded
                @click="sendClientEmailForAccessFacebook()"
                >Email Client For Access
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="6" class="text-center" v-if="this.showAdsAccount">
        <v-card class="compeatform">
          <v-row justify="center" align="center">
            <v-col cols="12" class="text-center">
              <h1 class="steptext">Insights Report Info</h1>
            </v-col>
            <v-col cols="12">
              <p class="mb-0">
                Please select the correct Ads account for the primary page
                <br />
                <p class="bold">{{ this.packagePages[0].name }}</p>
              </p>
            </v-col>
            <v-col cols="11">
              <v-select
                label="Select Ads Account"
                v-model="adsAccountId"
                :items="adsAccountsForPage"
                item-text="name"
                item-value="id"
              ></v-select>
            </v-col>
            <v-col cols="1" class="text-right py-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="primary"  class="icon-with-tooltip"
                    >mdi-comment-question-outline</v-icon
                  >
                </template>
                <span
                  >Please make sure you select the correct Ads account for the
                  primary page.<br> Else the campaigns and ads data will not be
                  correct.</span
                >
              </v-tooltip>
            </v-col>
            <v-col cols="12">
              <v-btn
                :loading="loading"
                @click="generateInsightsReportNow()"
                class="primary white--text"
                rounded
                >Generate Report</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="6" v-if="emailClientForAccessInstagram">
        <v-card class="compeatform">
          <v-row>
            <v-col cols="12" class="text-center">
              <h1 class="steptext">Link Accounts</h1>
            </v-col>
            <v-col cols="12" class="text-center">
              <p class="mb-0">
                Client needs to link their Facebook business account and
                Instagram business account to generate the Instagram Insights Reports.
              </p>
              <p class="mb-0">
                Please contact the page owner or the person with admin access.
              </p>
              <v-btn
                :loading="loading"
                @click="sendClientEmailForAccessInstagram()"
                class="primary white--text"
                rounded
                >Email Client For Access
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="6" v-if="thankYouForPurchasing">
        <v-card class="compeatform">
          <v-row>
            <v-col cols="12" class="text-center">
              <v-img
                src="../assets/thank-you-pic.jpg"
                width="60%"
                class="mx-auto"
              />
              <h1 class="steptext">Thank you for your purchase</h1>
            </v-col>
            <v-col cols="12" class="text-center">
              <p
                v-if="
                  competitiveReportUid != '' &&
                  instagramInsightsReportUid != '' &&
                  facebookInsightReportUid != ''
                "
                class="mb-0"
              >
                Your reports have been generated. Please check your emails your
                login details have been sent to you.
              </p>
              <p v-else class="mb-0">
                Your report have been generated. Please check your emails your
                login details have been sent to you.
              </p>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn
                :loading="loading"
                @click="openURL()"
                class="primary white--text"
                rounded
                >Go to Dashboard
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div class="px-5 py-5 text-center package1color white--text">
                  <h1 class="steptext mb-2">Want to CompEat?</h1>
                  <h2 class="text-bold steptext mb-2">EASY!!!</h2>
                  <!-- <p>In just a few steps and you will be ready to CompEat here we GOOOO!!!</p> -->
                  <!-- <img
                    src="http://compeatint.com/wp-content/uploads/2021/04/progress3.png"
                    width="60%"
                  /><br /> -->
                  <!-- <img src="../assets/step1.png" width="15%" /> -->
                  <p class="mb-0">Sign In using Facebook</p>

                  <img src="../assets/step1.png" width="15%" />
                  <p>
                    Enter in the company name as it is on Facebook and as
                    accurate as possible. CompEat will generate a detailed
                    report that compares your Brand’s Facebook page activity
                    with your competitors. Facebook handles will first be
                    validated before your billing information is processed, to
                    ensure your security.
                  </p>

                  <img src="../assets/step2.png" width="15%" class="mb-3" />
                  <p>
                    Your CompEat report will generate detailed information about
                    the performance of Facebook business pages. This information
                    will include details about the frequency and quality of
                    posts, how they are being engaged with (shares, likes,
                    comments, etc.), as well as campaign audiences.
                  </p>

                  <img src="../assets/step3.png" width="15%" class="mb-3" />
                  <p>
                    These reports allow you to compare your competitor’s
                    performance to your own, down to the finest details, so that
                    you can make meaningful adjustments to your Facebook Page
                    activity. This will ensure that you say ahead of the
                    competition, at all times
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <p class="mb-0 overlaytext">Please Wait...</p>
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "../firebaseConfig";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
const db = getFirestore(firebaseApp);
import addCard from "../components/dialogs/addCard.vue";
import generalAlert from "../components/alerts/generalAlert.vue";

export default {
  components: {
    addCard,
    generalAlert,
  },
  props: ["packagetype"],
  data: () => ({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    quantity: "",
    packageTitle: "",
    priceTotal: "",
    pagesToAdd: 5,
    quantity: 0,
    plan: "",
    packageUpgraded: false,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    checkboxRules: [
      (v) => !!v || "I agree to the terms & conditions must be checked",
    ],
    search: "",
    searchResults: [],
    searchPageError: false,
    searchErrorMessage: "",
    packagePages: [],
    packageUpgraded: false,
    loadingSearch: false,
    checkbox: false,
    displayBillingDetails: false,
    displayGeneratingReport: false,
    displayGeneratingInsightsReport: false,
    submitButton: true,
    valid: false,
    loading: false,
    currentSubscriber: [],
    newSubscriptionUid: "",
    newSubscription: [],
    reportType: "subscription",

    //error
    overlay: false,
    overlayError: false,
    overlayPaymentMethodError: false,
    catchErrorMessage: "",

    showCards: false,
    clientsCards: [],
    primaryCardId: "",
    otherCards: [],
    selectedCard: "",
    agencyPaying: false,

    stripeClient: [],
    competitiveReportUid: "",
    facebookInsightReportUid: "",
    instagramInsightsReportUid: "",
    alert: false,
    user: [],
    cancelledLogin: false,
    agencyProfile: [],
    primaryPageToken: "",
    emailClientForAccess: false,
    displayNextStep: false,
    showAdsAccount: false,
    adsAccountsForPage: [],
    adsets: {},
    campaigns: {},
    adsAccountId: "",
    emailClientForAccessInstagram: false,
    thankYouForPurchasing: false,
  }),
  created() {
    this.getAgencyInfo();
  },
  mounted() {
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    window.fbAsyncInit = function () {
      FB.init({
        appId: "736605293879783",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v16.0",
      });
    };
  },
  methods: {
    async getAgencyInfo() {
      this.isLoading = true;
      try {
        const docRef = doc(db, "agencies", "S83EWuzohPa3tVb98qjT");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          this.agencyProfile = docSnap.data();
          console.log("this.agencyProfile", this.agencyProfile);
          if (this.$store.state.facebookToken != "") {
            console.log(
              "this.$store.state.facebookToken",
              this.$store.state.facebookToken
            );
            this.checkTokenValid(this.$store.state.facebookToken);
          } else {
            this.$store.commit("setloggedIntoFacebook", false);
          }
        } else {
          console.log("No such document!");
          this.isLoading = false;
        }
      } catch (err) {
        this.isLoading = false;
        console.log("getAgencyInfo err", err);
      }
    },
    checkTokenValid(token) {
      this.overlay = true;
      const functions = getFunctions(firebaseApp, "europe-west2");
      const checkIfTokenIsValid = httpsCallable(
        functions,
        "checkIfTokenIsValid"
      );
      checkIfTokenIsValid({
        token: token,
      })
        .then((result) => {
          console.log("valid", result);
          if (result.data.valid) {
            this.$store.commit("setloggedIntoFacebook", true);
            this.$store.commit("setUserToken", token);
            this.getFacebookUserData(token);
          }
        })
        .catch((err) => {
          this.$store.commit("setloggedIntoFacebook", false);
          this.overlay = false;
        });
    },
    loginWithFacebook() {
      this.overlay = true;
      this.cancelledLogin = false;
      FB.login(
        (response) => {
          if (response.authResponse) {
            console.log(
              "User access token:",
              response.authResponse.accessToken
            );
            console.log("full response", response);
            this.$store.commit("setloggedIntoFacebook", true);
            this.extendUserFacebookToken(response.authResponse.accessToken);
          } else {
            this.overlay = false;
            this.cancelledLogin = true;
            setTimeout(() => {
              this.cancelledLogin = false;
            }, 5000);
            console.log("Cancelled login or did not fully authorize");
          }
        },
        {
          scope:
            "read_insights, pages_show_list, ads_management, ads_read, page_events, pages_read_engagement, pages_read_user_content, public_profile",
        }
      );
    },
    logoutFromFacebook() {
      this.overlay = true;
      let token = "";
      let user = [];
      this.$store.commit("setUser", user);
      this.$store.commit("setloggedIntoFacebook", false);
      this.$store.commit("setUserToken", token);
      FB.getLoginStatus((response) => {
        if (response.status === "connected") {
          var accessToken = response.authResponse.accessToken;
          console.log("logoutFromFacebook Response", response);
          FB.logout(
            function (response) {
              console.log("logout", response);
            },
            { access_token: accessToken }
          );
        }
      });
      this.overlay = false;
    },
    async extendUserFacebookToken(token) {
      const functions = getFunctions(firebaseApp, "europe-west2");
      const extendAccessToken = httpsCallable(functions, "extendAccessToken");

      extendAccessToken({
        token: token,
      }).then((result) => {
        console.log("Extend Token", result);
        this.checkTokenValid(result.data);
      });
    },
    getFacebookUserData(accessToken) {
      const self = this; // Capture the context of 'this'
      FB.api(
        "/me",
        "GET",
        { fields: "id,email,name" },
        function (response) {
          // Use 'self' instead of 'this' to access the component's context
          if (response && !response.error) {
            console.log("User data:", response);
            self.user = response;
            self.$store.commit("setUser", self.user);

            // You can access the user's information here: response.id, response.name, response.email
          } else {
            console.log("Error fetching user data:", response.error);
          }
        },
        { access_token: accessToken } // Include the access token here
      );

      if (this.packagetype == "monthly-package-1") {
        this.package1();
      } else if (this.packagetype == "monthly-package-2") {
        this.package2();
      } else if (this.packagetype == "annually-package-1") {
        console.log("Annual Package 1");
        this.annualPackage1();
      } else if (this.packagetype == "annually-package-2") {
        console.log("Annual Package 2");
        this.annualPackage2();
      }

      this.overlay = false;
    },
    // Adds pages from packagePages array and changes searchResults to false
    selectCompany(facebookpage) {
      this.packagePages.push(facebookpage);
      console.log(this.packagePages);
      this.searchResults = [];
      this.search = "";
      this.focus = false;
    },
    // delete pages from packagePages array
    deletePage(index) {
      console.log("index", index);
      this.packagePages.splice(index, 1);
    },
    searchForCompany() {
      this.loadingSearch = true;
      this.searchPageError = false;
      if (this.search.length < 2) {
        this.searchResults = [];
        return;
      } else if (this.search.length > 0) {
        this.searchResults = [];
      }

      const functions = getFunctions(firebaseApp, "europe-west2");
      const searchFacebookPage = httpsCallable(functions, "searchFacebookPage");

      const currentSearchQuery = this.search;

      searchFacebookPage({
        query: this.search,
      })
        .then((result) => {
          this.loadingSearch = true;
          if (this.search === currentSearchQuery) {
            let searchpages = result.data.pageData;
            this.searchResults = [];
            this.searchResults.push(...searchpages);
            this.focus = true;
          }
        })
        .then(() => {
          this.loadingSearch = false;
        })
        .catch((error) => {
          console.log(error);
          this.searchPageError = true;
          this.searchErrorMessage = "Something went wrong please try again";
        })
        .finally(() => {
          this.loadingSearch = false;
        });
    },
    //Step 1
    // Gets if user email is in our system
    async checkIfUserExist() {
      this.overlay = true;
      const q = query(
        collection(db, "users"),
        where("email", "==", this.email)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty == true) {
        console.log("no client");
        this.createNewFirebaseUser();
        //
      } else {
        querySnapshot.forEach((doc) => {
          let user = {
            data: {
              userId: doc.data().id,
            },
          };
          console.log("yes client");
          this.currentSubscriber = user;
        });
        this.createSubscription(this.currentSubscriber);
      }
    },
    //Step 2 if No Firebase user
    //Create User if they dont exist
    createNewFirebaseUser() {
      const functions = getFunctions(firebaseApp, "europe-west2");
      const createSubscriptionUser = httpsCallable(
        functions,
        "createSubscriptionUser"
      );

      createSubscriptionUser({
        name: this.firstName + " " + this.lastName,
        email: this.email,
        password: "12" + this.firstName + this.lastName + "@21",
        mobile: this.mobile,
        userType: "SB",
        agency: this.agencyProfile.agencyId,
      })
        .then((result) => {
          console.log(result);
          if (
            result.data.httpErrorCode != null &&
            result.data.httpErrorCode.status == 500
          ) {
            this.overlay = false;
            this.overlayError = true;
            this.catchErrorMessage = result.data.httpErrorCode.status;
          } else {
            this.currentSubscriber = result;
            this.createSubscription(this.currentSubscriber);
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.overlay = false;
          this.overlayError = true;
          this.catchErrorMessage = error.message;
        });
    },
    //Step 2 if user exists in firebase
    //Step 3 after creating new firebase user
    // Creates subscription via Cloud function
    createSubscription(user) {
      console.log("user currentSubscriber", this.currentSubscriber);
      const functions = getFunctions(firebaseApp, "europe-west2");
      const createSubscription = httpsCallable(functions, "createSubscription");

      createSubscription({
        name: this.firstName + " " + this.lastName,
        mobile: this.mobile,
        email: this.email,
        subscriberId: user.data.userId,
        userType: "Subscriber",
        package: {
          currentPackage: this.packageTitle,
          primaryPage: this.packagePages[0],
          pages: this.packagePages,
          quantity: this.quantity,
          priceTotal: this.priceTotal,
        },
        agencyId: this.agencyProfile.agencyId,
        agencyName: this.agencyProfile.agencyName,
        agencyLogo: this.agencyProfile.agencyLogo,
        agencyUser: "FcmcB4XLWnPB9UxFS1YYkUN3zv03",
        dateCreated: Date.now(),
        isActive: true,
        paymentStatus: "Pending",
      })
        .then((result) => {
          console.log("result", result);
          this.newSubscription = result.data.data;
          this.newSubscriptionUid = result.data.data.uid;
          this.submitButton = false;
          this.displayBillingDetails = true;
          this.searchForCustomerEmail();
        })
        .catch((error) => {
          console.log("error", error);
          this.overlay = false;
          this.overlayError = true;
          this.catchErrorMessage = error.message;
        });
    },
    //Checking for clients email in stripe system
    searchForCustomerEmail() {
      try {
        this.otherCards = [];
        this.defaultCard = [];
        this.primaryCardId = [];
        this.clientsCards = [];
        this.selectedCard = "";

        const functions = getFunctions(firebaseApp, "europe-west2");
        const searchForStripeClientEmail = httpsCallable(
          functions,
          "searchForStripeClientEmail"
        );

        searchForStripeClientEmail({
          subscription: this.newSubscription,
        }).then((result) => {
          console.log("result", result);
          this.overlay = false;
          if (
            result.data.httpErrorCode != null &&
            result.data.httpErrorCode.status == 500
          ) {
            this.overlay = false;
            this.overlayPaymentMethodError = true;
            this.catchErrorMessage = `${result.data.httpErrorCode.status} Error fetching clients payment methods, try agency cards.`;
          } else if (
            result.data.message ==
            "New Customer Created need to add payment Method"
          ) {
            // this.displayCardFeilds = true
            this.showCards = true;
            this.stripeClient = result.data.customer;
            // this.loadingSearchClient = false;
            console.log("New Customer created");
            this.overlay = false;
          } else {
            console.log("Current Customer created");
            this.overlay = false;
            this.showCards = true;
            this.clientsCards = result.data.paymentMethods;
            this.primaryCardId =
              result.data.customer.invoice_settings.default_payment_method;

            if (this.primaryCardId) {
              this.clientsCards.forEach((item) => {
                if (item.id == this.primaryCardId) {
                  this.defaultCard = item;
                } else {
                  this.otherCards.push(item);
                }
              });

              this.otherCards.unshift(this.defaultCard);
              this.selectedCard = this.otherCards[0].id;
              this.overlay = false;

              this.stripeClient = result.data.customer;
              console.log("stripe client", this.stripeClient);
              this.overlay = false;
            } else {
              this.otherCards = [];
              this.overlay = false;

              this.stripeClient = result.data.customer;
              console.log("stripe client", this.stripeClient);
              this.overlay = false;
            }
          }
        });
      } catch (err) {
        console.log("err", err);
        this.overlay = false;
        this.overlayPaymentMethodError = true;
        this.catchErrorMessage = error.message;
      }
    },
    submitCardDetails() {
      this.overlay = true;
      let whosPaying = "client";
      this.createNewStripeSubscription(this.stripeClient.id, whosPaying);
      console.log("Client");
    },
    createNewStripeSubscription(stripeCustomerId, paidBy) {
      try {
        this.loading = true;
        const pageIds = this.packagePages.map((page) => page.id);
        const functions = getFunctions(firebaseApp, "europe-west2");
        const createStripeSubscription = httpsCallable(
          functions,
          "createStripeSubscription"
        );

        createStripeSubscription({
          stripeCustomerId: stripeCustomerId,
          newSubscriptionUid: this.newSubscriptionUid,
          pages: pageIds.join(","),
          plan: this.plan,
          packageTitle: this.packageTitle,
          subscriber: this.firstName + " " + this.lastName,
          paidBy: paidBy,
          defaultPaymentMethod: this.selectedCard,
        }).then((result) => {
          console.log("result", result);
          if (
            result.data.httpErrorCode != null &&
            result.data.httpErrorCode.status == 500
          ) {
            this.loading = false;
            this.overlay = false;
            this.overlayError = true;
            this.catchErrorMessage = `${result.data.httpErrorCode.status} error. Subscription was not created. Please try other payment method.`;
          } else {
            this.invoiceUrl = result.data.invoice.hosted_invoice_url;
            this.invoiceId = result.data.invoice.id;
            this.paymentIntentId = result.data.paymentIntent.id;

            if (result.data.invoice.paid == true) {
              console.log("invoice paid", result);
              this.showCards = false;
              this.displayBillingDetails = false;
              this.displayGeneratingReport = true;
              this.alert = true;
              this.alertType = "success";
              this.successResponse = "Payment Was Successfull!";

              setTimeout(() => {
                this.alert = false;
              }, 5000);
              this.createReport();
            }
          }
        });
      } catch (err) {
        console.log("err", err);
        this.loading = false;
        this.overlay = false;
        this.overlayError = true;
        this.catchErrorMessage = err.message;
      }
    },
    // Create report
    createReport() {
      try {
        const pageIds = this.packagePages.map((page) => page.id);
        const functions = getFunctions(firebaseApp, "europe-west2");
        const generateCompetitiveAnalysisReport = httpsCallable(
          functions,
          "generateCompetitiveAnalysisReport"
        );

        generateCompetitiveAnalysisReport({
          pageIds: pageIds,
          agencyId: this.agencyProfile.agencyId,
          subscriptionUid: this.newSubscriptionUid,
          invoiceId: this.invoiceId,
          paymentIntentId: this.paymentIntentId,
          reportType: "subscription",
        })
          .then((result) => {
            console.log(result);
            this.competitiveReportUid = result.data.reportUid;
            this.displayGeneratingReport = false;
            this.displayNextStep = true;
            this.emailClient();
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.showCards = false;
            this.displayBillingDetails = true;
            this.overlay = false;
            this.overlayError = true;
            this.catchErrorMessage = `${error.message} error. Please try again or contact your account manager.`;
          });
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.showCards = false;
        this.displayBillingDetails = false;
        this.overlay = false;
        this.overlayError = true;
        this.catchErrorMessage = `${error.message}`;
      }
    },
    // Cloud function to email subscriber logins once the report has been generated
    emailClient() {
      // const pageNames = this.packagePages.map(page => page.name);

      const functions = getFunctions(firebaseApp, "europe-west2");
      const emailSubscriberLogins = httpsCallable(
        functions,
        "emailSubscriberLogins"
      );

      emailSubscriberLogins({
        subscriberEmail: this.email,
        subscriptionId: this.newSubscriptionUid,
        reportId: this.competitiveReportUid,
        agency: this.agencyProfile,
        packageTitle: this.packageTitle,
        packagePages: this.packagePages,
        subscriberName: this.firstName + " " + this.lastName,
        subscriberPassword: "12" + this.firstName + this.lastName + "@21",
        stripeInvoiceUrl: this.invoiceUrl,
      })
        .then((result) => {
          console.log(result);
          if (result.data.success == true) {
            this.loading = false;
            this.checkForPageAccess();
          } else {
            console.log("email error");
            this.overlay = false;
            this.overlayEmailError = true;
            this.catchErrorMessage = `Email was not sent. Please email client from subscription page.`;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.overlay = false;
          this.overlayError = true;
          this.catchErrorMessage = `${error.message} test`;
        });
    },
    skipEmailFailure() {
      this.skipEmailError = false;
      this.checkForPageAccess();
      this.overlay = true;
    },
    checkForPageAccess() {
      const functions = getFunctions(firebaseApp, "europe-west2");
      const getPageToken = httpsCallable(functions, "getPageToken");

      getPageToken({
        userToken: this.$store.state.facebookToken,
        pageId: this.packagePages[0].id,
      }).then((result) => {
        console.log("result getting token", result);
        this.displayGeneratingReport = false;
        this.loading = false;
        this.primaryPageToken = result.data;
        if (this.primaryPageToken == "") {
          this.emailClientForAccess = true;
          this.overlay = false;
        } else {
          this.getAdsAccountsForPage(result);
        }
      });
    },
    sendClientEmailForAccessFacebook() {
      this.overlay = true;
      const functions = getFunctions(firebaseApp, "europe-west2");
      const emailSubscriberAccessForPage = httpsCallable(
        functions,
        "emailSubscriberAccessForPage"
      );

      emailSubscriberAccessForPage({
        userEmail: this.email,
        pageName: this.packagePages[0].name,
        subscriptionUid: this.newSubscriptionUid,
        competitiveReportUid: this.competitiveReportUid,
      }).then((result) => {
        this.emailClientForAccess = false;
        this.displayNextStep = false;
        this.thankYouForPurchasing = true;
        this.overlay = false;
      });
    },
    getAdsAccountsForPage() {
      const functions = getFunctions(firebaseApp, "europe-west2");
      const getAdAccountId = httpsCallable(functions, "getAdAccountId");

      getAdAccountId({
        userToken: this.$store.state.facebookToken,
      }).then((result) => {
        this.overlay = false;
        let array = result.data;

        let emptyItem = {
          name: "No Ad Account For Page",
          id: "0",
          account_id: "0",
        };

        this.adsAccountsForPage = [emptyItem, ...array];

        if (this.adsAccountsForPage.length != 1) {
          this.displayNextStep = false;
          this.showAdsAccount = true;
        } else if (this.adsAccountsForPage.length == 1) {
          this.dontShowAdsAccount = true;
        }
      });
    },
    generateInsightsReportNow() {
      this.overlay = true;
      this.showAdsAccount = false
      this.displayGeneratingInsightsReport = true
      const functions = getFunctions(firebaseApp, "europe-west2");
      const generateInsightsReport = httpsCallable(
        functions,
        "generateInsightsReport"
      );

      generateInsightsReport({
        userToken: this.$store.state.facebookToken,
        pageId: this.packagePages[0].id,
        adAccountId: this.adsAccountId,
        pageToken: this.primaryPageToken,
        agencyId: this.agencyProfile.agencyId,
        subscriptionId: this.newSubscriptionUid,
      })
        .then((result) => {
          console.log(" generateInsightsReportNow result", result);
          this.adsets = result.data.adsetsInstagram;
          this.campaigns = result.data.campaignsInstagram;
          console.log("this.adsets", this.adsets);
          console.log("this.campaigns", this.campaigns);
          this.facebookInsightReportUid = result.data.facebookInsightsReport;
          console.log(
            "this.facebookInsightReportUid",
            this.facebookInsightReportUid
          );
          this.checkforInstagramAccountID();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkforInstagramAccountID() {
      const functions = getFunctions(firebaseApp, "europe-west2");
      const getInstagramAccountId = httpsCallable(
        functions,
        "getInstagramAccountId"
      );

      getInstagramAccountId({
        pageId: this.packagePages[0].id,
        userToken: this.$store.state.facebookToken,
      }).then((result) => {
        let response = result.data;
        if (response.instagram_business_account) {
          console.log(
            "got instagram_business_account",
            response.instagram_business_account.id
          );
          this.generateInstgramInsightsReport(
            response.instagram_business_account.id
          );
        } else {
          console.log("no instagram_business_account");
          this.displayGeneratingInsightsReport = false
          this.emailClientForAccessInstagram = true;
          this.overlay = false;
        }
      });
    },
    generateInstgramInsightsReport(igAccountId) {
      const functions = getFunctions(firebaseApp, "europe-west2");
      const generateInstagramInsights = httpsCallable(
        functions,
        "generateInstagramInsights"
      );

      generateInstagramInsights({
        userToken: this.$store.state.facebookToken,
        accountId: igAccountId,
        subscriptionUid: this.newSubscriptionUid,
        competitiveReportUid: this.competitiveReportUid,
        facebookInsightsReportUid: this.facebookInsightReportUid,
        adsets: this.adsets,
        campaigns: this.campaigns,
        adAccountId: this.adsAccountId,
      }).then((result) => {
        this.instagramInsightsReportUid = result.data.instagramInsightsReport;
        console.log(
          "instagramInsightsReportUid",
          result.data.instagramInsightsReport
        );
        this.displayGeneratingInsightsReport = false
        this.thankYouForPurchasing = true;
        this.adsAccountsForPage = [];
        this.adsAccountId = "";
        this.primaryPageToken = "";
        this.packagePages = [];
        this.overlay = false;
        this.alert = true;
        this.alertType = "success";
        this.successResponse =
          "Subscription, Competitive Analysis Report, Facebook Insights Report and Instagram Insights Created Successfully.";

        setTimeout(() => {
          this.alert = false;
        }, 5000);
      });
    },
    sendClientEmailForAccessInstagram() {
      this.overlay = true;
      const functions = getFunctions(firebaseApp, "europe-west2");
      const emailSubscriberAccessForInstagamPage = httpsCallable(
        functions,
        "emailSubscriberAccessForInstagamPage"
      );

      emailSubscriberAccessForInstagamPage({
        userEmail: this.email,
        pageName: this.packagePages[0].name,
        subscriptionUid: this.newSubscriptionUid,
        competitiveReportUid: this.competitiveReportUid,
        facebookInsightsReportUid: this.facebookInsightReportUid,
      }).then((result) => {
        console.log("sendClientEmailForAccessInstagram result", result);
        this.emailClientForAccessInstagram = false;
        this.thankYouForPurchasing = true;
        this.adsAccountsForPage = [];
        this.adsAccountId = "";
        this.primaryPageToken = "";
        this.packagePages = [];
        this.competitiveReport = true;
        this.alert = true;
        this.alertType = "success";
        this.successResponse =
          "Subscription and Competitive Analysis Report and Facebook Insights Report Created Successfully. Awaiting For Instagram to be linked to facebook.";
        setTimeout(() => {
          this.alert = false;
        }, 5000);
        this.overlay = false;
      });
    },
    handleCheckboxChange(itemId) {
      if (itemId) {
        this.selectedCard = itemId;
        console.log("this.selectedCard", this.selectedCard);
      }
    },
    openURL() {
      const url = "https://compeat-int.web.app";
      window.location.href = url;
    },
    //Package 1 setup
    package1() {
      this.quantity = 1;
      this.packageTitle = "Monthly Package 1";
      this.priceTotal = 185;
      this.pagesToAdd = 5;
      this.plan = "price_1NVV5lAGtLYXowcOQGKi9GVJ";
    },
    //Package 2 setup
    package2() {
      this.quantity = 1;
      this.packageTitle = "Monthly Package 2";
      this.packageUpgraded = true;
      this.priceTotal = 235;
      this.pagesToAdd = 10;
      this.plan = "price_1NVV6hAGtLYXowcOJMuRyyoP";
    },
    //Package 1 setup
    annualPackage1() {
      this.quantity = 1;
      this.packageTitle = "Annual Package 1";
      this.priceTotal = 1925;
      this.pagesToAdd = 5;
      this.plan = "price_1NVV5lAGtLYXowcOQGKi9GVJ";
    },
    //Package 2 setup
    annualPackage2() {
      this.quantity = 1;
      this.packageTitle = "Annual Package 2";
      this.packageUpgraded = true;
      this.priceTotal = 2395;
      this.pagesToAdd = 10;
      this.plan = "price_1NVV6hAGtLYXowcOJMuRyyoP";
    },
    onInput(formattedNumber, { number }) {
      this.mobile = number.international;
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
<style>
.compeatform {
  padding: 30px;
  border-radius: 20px;
  box-shadow: inset 7px 7px 14px #d9d9d9, inset -7px -7px 14px #ffffff;
}

.package1color {
  background: rgba(80, 83, 223, 0.6);
  border-radius: 20px;
}

.steptext {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  /* line-height: 45px; */
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
